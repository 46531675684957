<template>
<!-- 用户管理总系统 -->
  <div>
    <div class="unit_cont_box">
      <div class="iconfont iconjia settingAdd" @click="handleUserAdd()"><span>新增</span></div>
        <div class="flexAC ut_top">
          <el-input v-model="account" clearable  placeholder="请输入账号/姓名/手机号/单位" class="contInput"></el-input>
          <el-select v-model="online" placeholder="在线状态" clearable class="utF setSe">
            <el-option
              v-for="(item,index) in onlineList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="userstate" placeholder="启用状态" clearable class="utF setSe">
            <el-option
              v-for="(item,index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="provinceValue" placeholder="省" clearable  @change="chooseProvince" class="utF setSe">
            <el-option	
                v-for="item in provinceData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          <el-select v-model="cityValue" placeholder="市" clearable  @change="chooseCity" class="utF setSe">
            <el-option	
                v-for="item in cityData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          <el-select v-model="areaValue" @change="chooseArea" clearable  placeholder="区" class="utF setSe">
            <el-option
                v-for="item in areaData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          <el-select v-model="rolename" placeholder="角色" clearable  class="utF setSe">
            <el-option
              v-for="item in roleList"
              :key="item.index"
              :label="item.rolename"
              :value="item.roleid">
            </el-option>
          </el-select>
          <el-button class="settingSearch" @click="handleSearch()">搜索</el-button>
          <el-button class="settingReset" @click="handleReset()">重置</el-button>
        </div>
      </div>
      <div class="user_table">
      <el-table
        stripe
        :data="userList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="在线状态"
          prop="online">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.online == 1">在线</el-tag>
            <el-tag type="danger" v-if="scope.row.online == 2">离线</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="地址"
          show-overflow-tooltip
          prop="address">
        </el-table-column>
        <el-table-column
          label="账号"
          prop="account">
        </el-table-column>
        <el-table-column
          label="单位名称"
          show-overflow-tooltip
          prop="firebrig">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="username">
        </el-table-column>
        <el-table-column
          label="电话"
          prop="userphone">
        </el-table-column>
        <el-table-column
          label="入网时间"
          show-overflow-tooltip
          prop="creatime">
        </el-table-column>
        <el-table-column
          label="IP锁定"
          align="center"
          prop="userip">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              v-model="scope.row.userip"
              :validate-event="true"
              active-text="打开"
              inactive-text="关闭"
              active-color="#52C41A"
              inactive-color="#A4AFC8"
              :active-value="1"
              :inactive-value="0"
              disabled
              @click.native.prevent="handleIP(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="启用状态"
          prop="userstate">
          <template slot-scope="scope">
            <el-switch
              class="tableScopeSwitch"
              v-model="scope.row.userstate" 
              :validate-event="true"
              active-text="启用"
              inactive-text="禁用"
              active-color="#52C41A"
              inactive-color="#A4AFC8"
              :active-value="true"
              :inactive-value="false"
              disabled
              @click.native.prevent="handleState(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
          <el-table-column
          label="异地状态"
          prop="userval">
          <template slot-scope="scope">
            <el-switch
              class="tableSwitch"
              v-model="scope.row.userval"
              :validate-event="true"
              active-text="打开"
              inactive-text="关闭"
              active-color="#52C41A"
              inactive-color="#A4AFC8"
              :active-value="1"
              :inactive-value="2"
              disabled
              @click.native.prevent="haldleAnonPlace(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="180">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleSet(scope.row)">通知设置</el-button>
            <el-button type="text" size="small" @click="handleResetPwd(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[size]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>
    <!-- 新增弹窗 -->
    <el-dialog title="新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="角色"   prop="roleid" >
          <el-select v-model="addfrom.roleid" placeholder="请选择角色" style="width:550px;">
            <el-option
                v-for="item in roleList"
                :key="item.roleid"
                :label="item.rolename"
                :value="item.roleid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号"  prop="account">
          <el-input v-model="addfrom.account"  placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="名称"  prop="username">
          <el-input v-model="addfrom.username"  placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="电话"  prop="userphone">
          <el-input v-model="addfrom.userphone"  placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="监管地区" required>
          <div style="width: 100%;display: flex;justify-content: space-around;">
            <el-select v-model="provinceValue" placeholder="省" @change="chooseProvince" style="width: 160px">
            <el-option	
                v-for="item in provinceData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          <el-select v-model="cityValue" placeholder="市" @change="chooseCity" style="width: 160px;margin: 0 20px;">
            <el-option	
                v-for="item in cityData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          <el-select v-model="areaValue" @change="chooseArea" placeholder="区" style="width: 160px">
            <el-option
                v-for="item in areaData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          </div>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 通知设置弹窗 -->
    <el-dialog title="通知设置" :visible.sync="showSet" width="910px" center :close-on-click-modal="false">
      <InfoSet :Info = "Info" @close="setCancel" @save="setSave"></InfoSet>
    </el-dialog>
  </div>
</template>

<script>
import { userTot, getDivision, getRole, addUser, userEnable, userIplock, enableStop, resetUserInfo } from '@/api/user.js'
import InfoSet from '@/components/infoSet.vue'

export default {
  components: {
    InfoSet
  },
  data () {
    const userphone = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入用户电话'))
      }
      if(value){
        if ( /^1/.test( value ) ) {
          if ( /^1\d{10}$/.test( value ) ) {
            callback();
          } else {
            callback( new Error( "请输入11位的手机号" ) );
          }
        } else if ( /^0/.test( value ) ) {
          if ( /^0\d{2,4}-?\d{6,9}$/.test( value ) ) {
            callback();
          } else {
            callback( new Error( "请输入正确的座机号" ) );
          }
        }else{
          callback( new Error( "请输入11位手机号或座机号" ) );
        }
      }else{
          callback();
      }
    }
    return {
      activeName: '1',
      account: '',
      page: 1,
      size: 10,
      total: 0,
      userList: [],
      online: '',
      onlineList: [
        { label: '在线', value: 1 },
        { label: '离线', value: 2 }
      ],
      userstate: '',
      stateList: [
        { label: '启用', value: 2 },
        { label: '停用', value: 1 }
      ],
      provinceValue: '',
      cityValue: '',
      areaValue: '',
      provinceData: [],
      cityData: [],
      areaData: [],
      roleid: '',
      rolename: '',
      roleList: [],
      addFromShow: false,
      addfrom: {
        account: '',
        roleid: '',
        username: '',
        userphone: '',
        address: ''
      },
      formRules: {
        roleid: [{ required: true, trigger: 'blur', message: '请选择角色' }],
        account: [
          { required: true, trigger: 'blur', message: '账号不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, trigger: 'blur', message: '名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        userphone: [{ required: true, validator: userphone, trigger: 'blur' }],
        address: [{ required: true, trigger: 'blur', message: '地区不能为空' }],
      },
      showSet: false,
      Info: ''
    }
  },
  created () {
    this.getInit()
    this.init()
  },
  methods: {
    init () {
      const parmas = {
        account: this.account,
        online: this.online,
        userstate: this.userstate,
        roleid: this.rolename,
        sheng: this.provinceValue,
        shi: this.cityValue,
        xian: this.areaValue,
        page_index: this.page,
        page_size: this.size
      }
      userTot(parmas).then(res => {
        if (res.status === '1') {
          if (res.data.data !== undefined || res.data.data !== null) {
            this.userList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.userList = []
            this.total = 0
          }
        } else {
          this.userList = []
          this.total = 0
        }
      })
    },
    getInit() {
      // 查询角色
      getRole({ roletype: '1' }).then(res => {
        if (res.status === '1') {
          if (res.data !== undefined || res.data!== null) {
            this.roleList = res.data
          } else {
            this.roleList = []
          }
        } else {
          this.roleList = []
        }
      })
      // 查询省份
      getDivision().then(res => {
        if (res.status === '1') {
          if (res.data !== undefined || res.data!== null) {
            this.provinceData = res.data
            // this.provinceValue = this.provinceData[0].name
          } else {
            this.provinceData = []
          }
        } else {
          this.provinceData = []
        }
      })
    },
    // 新增
    handleUserAdd () {
      this.addFromShow = true
      this.getInit()
    },
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        var address = this.provinceValue + '' + this.cityValue + '' + this.areaValue
        const params = {
          account: this.addfrom.account,
          roleid: this.addfrom.roleid,
          username: this.addfrom.username,
          userphone: this.addfrom.userphone,
          address: address,
        }
        addUser(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    // 通知设置
    handleSet (row) {
      this.showSet = true
      this.Info = row
    },
    setSave (res) {
      this.showSet = false
      this.init()
    },
    setCancel () {
      this.showSet = false
    },
    // 密码重置
    handleResetPwd () {
      this.$confirm('重置密码后将会恢复到初始密码“888888”,请确定是否重置?', '重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        resetUserInfo({ roleid: row.roleid, userid: row.userid }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    // IP锁定
    handleIP (row) {
      this.$confirm('您将对账号IP锁定进行修改,是否继续?', 'IP锁定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        userIplock({ roleid: row.roleid, userid: row.userid }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    // 启用状态
    handleState (row) {
      this.$confirm('您将对账号启用状态进行修改,是否继续?', '启用状态', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        userEnable({ roleid: row.roleid, userid: row.userid }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    // 异地状态
    haldleAnonPlace (row) {
      this.$confirm('您将对账号异地状态进行修改,是否继续?', '异地状态', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        enableStop({ roleid: row.roleid, userid: row.userid }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    // 选择省份
    chooseProvince (val) {
      this.provinceValue = val
      this.cityValue = ''
      this.areaValue = ''
      this.cityData = []
      this.areaData = []
      getDivision({ sheng: val }).then(res => {
         if (res.status === '1') {
           this.cityData = res.data
         } else {
           this.cityData = []
         }
      })
    },
    // 选择市
    chooseCity (val) {
      this.cityValue = val
      this.areaValue = ''
      this.areaData = []
      getDivision({ sheng: this.provinceValue, shi: val }).then(res => {
        if (res.status === '1') {
          if (res.data !== undefined || res.data!== null) {
            this.areaData = res.data
          } else {
            this.areaData = []
          }
        } else {
          this.areaData = []
        }
      })
    },
    // 选择区
    chooseArea (val) {
       this.areaValue = val
    },
    handleClick(tab, event) {
      this.activeName = tab.name
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.account = ''
      this.online = ''
      this.userstate = ''
      this.rolename = ''
      this.provinceValue = ''
      this.cityValue = ''
      this.areaValue = ''
      this.cityData = []
      this.areaData = []
      this.init()
    },
  }
}
</script>

<style scoped>
</style>
