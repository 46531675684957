<template>
  <div>
    <el-form :model="setfrom" :rules="setformRules" ref="setFrom">
      <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
        <div class="udi_k"></div>
        <p class="udi_t">告警弹窗</p>
      </div>
      <el-form-item label="" class="foItem">
        <el-radio-group v-model="setfrom.homepage" style="width:550px;">
          <el-radio label="1">开启</el-radio>
          <el-radio label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">告警通知</p>
        </div>
        <div class="flexCB">
          <el-form-item label="火警信息" class="noteItem">
            <el-checkbox  v-model="setfrom.firealarm.rat" true-label="1" false-label="2" >平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.firealarm.sms" true-label="1" false-label="2" >短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.firealarm.voe" true-label="1" false-label="2" >语音通知</el-checkbox>
          </el-form-item>
          <el-form-item label="故障" class="noteItem">
              <el-checkbox  v-model="setfrom.faultalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
              <el-checkbox  v-model="setfrom.faultalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
              <el-checkbox  v-model="setfrom.faultalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
            </el-form-item>
          </div>
          <div class="flexCB">
          <el-form-item label="隐患" class="noteItem">
            <el-checkbox  v-model="setfrom.hiddalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.hiddalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.hiddalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
          <el-form-item label="其他" class="noteItem">
            <el-checkbox  v-model="setfrom.otheralarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.otheralarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.otheralarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
          </div>
        </div>
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">其他通知</p>
          </div>
        </div>
        <div class="flexCB">
        <el-form-item label="巡检通知" class="noteItem">
          <el-checkbox  v-model="setfrom.inspealarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
          <el-checkbox  v-model="setfrom.inspealarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
          <el-checkbox  v-model="setfrom.inspealarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
        </el-form-item>
        <el-form-item label="逾期通知" class="noteItem">
          <el-checkbox  v-model="setfrom.withialarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
          <el-checkbox  v-model="setfrom.withialarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
          <el-checkbox  v-model="setfrom.withialarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
        </el-form-item>
        </div>
      <el-form-item label="告警通知" class="noteItem">
        <el-checkbox  v-model="setfrom.declalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
        <el-checkbox  v-model="setfrom.declalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
        <el-checkbox  v-model="setfrom.declalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
      </el-form-item>
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="setCancel">取消</div>
      <div class="settingConfirm" @click="setFromConfirm">确定</div>
    </div>
  </div>
</template>

<script>
import { getnoticeSet, noticeSetup } from '@/api/user.js'
import { deflate } from 'zlib';

export default {
  props: ['Info'],
  data () {
    return {
      setfrom: {
        homepage: '1',
        firealarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        faultalarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        hiddalarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        otheralarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        inspealarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        withialarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        declalarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        }
      },
      setformRules: {},
    }
  },
  watch: {
    Info () {
      this.setNotice()
    }
  },
  mounted () {
    this.setNotice()
  },
  methods: {
    setNotice () {
      getnoticeSet({ 
        roleid: this.Info.roleid,
        usermsgid: this.Info.usermsgid,
        userid: this.Info.userid
       }).then(res => {
        if (res.status === '1') {
          this.setfrom =  res.data
          // this.setfrom.firealarm = res.data.firealarm
          // this.setfrom.declalarm = res.data.declalarm
          // this.setfrom.faultalarm = res.data.faultalarm
          // this.setfrom.hiddalarm = res.data.hiddalarm
          // this.setfrom.inspealarm = res.data.inspealarm
          // this.setfrom.otheralarm = res.data.otheralarm
          // this.setfrom.withialarm = res.data.withialarm
          // this.setfrom.homepage = res.data.homepage
        } else {
          this.setfrom = {}
        }
      })
    },
    setFromConfirm () {
      this.$refs.setFrom.validate((valid) => {
        if (!valid) return
        const params = {
          usermsgid: this.Info.usermsgid,
          roleid: this.Info.roleid,
          userid: this.Info.userid,
          homepage: this.setfrom.homepage,
          firealarm: this.setfrom.firealarm,
          faultalarm: this.setfrom.faultalarm,
          hiddalarm: this.setfrom.hiddalarm,
          otheralarm: this.setfrom.otheralarm,
          inspealarm: this.setfrom.inspealarm,
          withialarm: this.setfrom.withialarm,
          declalarm: this.setfrom.declalarm
        }
        noticeSetup(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.setCancel()
            // this.setNotice()
            this.$emit('save')
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    setCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
