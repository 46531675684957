<template>
  <div>
    <el-breadcrumb separator="">
      <el-breadcrumb-item  class="sptor">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="activeName == 1" class="sptor isactive">总系统</el-breadcrumb-item>
      <el-breadcrumb-item v-if="activeName == 2" class="sptor isactive">子系统</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="setting_main_bg">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 总系统 -->
        <el-tab-pane label="总系统" name="1">
          <UserTot v-if="activeName == 1"></UserTot>
        </el-tab-pane>
        <!-- 子系统 -->
        <el-tab-pane label="子系统" name="2">
          <UserSub v-if="activeName == 2"></UserSub>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import UserSub from '@/components/userSub.vue'
import UserTot from '@/components/userTot.vue'

export default {
  components: {
    UserSub,
    UserTot
  },
  data () {
    return {
      activeName: '1'
    }
  },
  created () {
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
    }
  }
}
</script>

<style scoped>
.isactive >>> .el-breadcrumb__inner {
  color: #196DF7;
}
</style>
